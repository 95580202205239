
const $ = require('jquery');
window.$ = window.jQuery = $;


import 'jquery-pjax'
import 'bootstrap'
import 'underscore';
import './../../assets/bundles/prccore/lib/widgster/widgster.js';
import './../../assets/bundles/prccore/js/settings.js';

import '../scss/core_layout_login.scss';
